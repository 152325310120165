import React from 'react';
import { motion } from "framer-motion";
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {graphql} from 'gatsby';
import styled from "styled-components";

import { colors } from "../style/style";

import {SubTitle} from "../components/PageParts";
import Layout from '../components/layout';
import PageHeader, { StyledButton } from '../components/PageHeader/PageHeader';
import SlideFooter from "../components/SlideFooter";
import SlideInElement from "../components/SlideInElement/SlideInElement";
import { fadeInLeft, fadeInRight, fadeInUp } from "../style/motion-variants";

import bgDarkElement from "../static/images/bg-element-dark.svg"

const Section = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  max-width: 1260px;
  margin: 0 auto;
  padding: 100px 30px;
`

const Message = styled.p`
  font-size: 18px;
  line-height: 145%;
  font-weight: 500;
  margin: 20px 0 50px;
`

export const thankYouQuery = graphql`{
    seo: datoCmsSeo(slug: {eq: "thankYou"}) {
        meta: seoMetaTags {
            tags
        }
    }
}`;

const ThankYou = ({data}) => {
    const {
        seo
    } = data;

    return <Layout>
        <HelmetDatoCms seo={seo?.meta}/>
        <PageHeader/>

        <Section>
            <SlideInElement
              top={ 167 }
              left={ -370 }
              bg={ bgDarkElement }
              variant={ fadeInLeft }
            />

            <SlideInElement
              bottom={ 150 }
              right={ 50 }
              bg={ bgDarkElement }
              variant={ fadeInRight }
            />
            
            <motion.div
              exit="exit"
              variants={ fadeInUp }
              initial="initial"
              animate="animate"
            >
              <SubTitle color={colors.dark} maxWidth={600}>Thank you for getting in touch with us.</SubTitle>
              <Message>We will contact you shortly</Message>
              <StyledButton
                  style={ { display: "inline-block", textAlign: "center" } }
                  to={ "/" }
                  color={ colors.dark }
              >
                Continue
              </StyledButton>
            </motion.div>
        </Section>

        <SlideFooter/>
    </Layout>
}

export default ThankYou;
